/* eslint-disable no-undef, react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import { css } from 'react-emotion';
import { Helmet } from 'react-helmet';
import { PaperBox, Flex } from '../components/Layout';
import PageWrapper from '../components/PageWrapper';
import colors from '../utils/colors';
import BackgroundImage from 'gatsby-background-image';

const imgStyle = css`
  border-radius: 5px;
`;

const mainName = css`
  text-align: left;
  font-size: 1.2em;
  font-weight: bold;
`;

const top = css`
  padding-top: 40px;
  text-align: center;
  @media screen and (max-width: 600px) {
    padding-top: 70px;
    }
`;

const bottom = css`
  padding-bottom: 40px;
`;

const padding = css`
  padding-top: 50px;
  @media screen and (max-width: 600px) {
      padding-top: 100px;
    }
`;

const welcomeText = css`
  padding-bottom: 10px;
  padding-top: 0px;
`;

const Holding = ({ data }) => {
  const mapData = data.map.childImageSharp;
  return (
      <BackgroundImage
        Tag="section"
        className={imgStyle}
        fluid={mapData.fluid}
        backgroundColor={`#FFFFFF`}
        padding="20px"
        margin="40px"
      >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home - Beacons</title>
      </Helmet>

        <Flex>
          <PaperBox 
            width={[1, '80%', '60%']}
            ml="auto"
            mr="auto"
            mt="50px"
            px={[3, 3, 5]}
            py={[3, 3, 3]}
            color={colors.secondary}
            textAlign="center"
          >
            <p>*BEACONS*</p>

            <p>*The Quest starts here...*</p>

            <p>*20th November - 19th December*</p>

            <p>Want to be the first to know when the Sea Gooseberries return?</p>

            <p>@seagooseberries (instagram/facebook/twitter)</p>
          </PaperBox>
        </Flex>
      </BackgroundImage>
  );
};

export const query = graphql`
query {
  map: file(relativePath: { eq: "map.jpeg" }) {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  allContentJson {
		edges {
			node {
				index {
					title
					aboutText
				}
      }
    }
  }
}
`;

export default Holding;
